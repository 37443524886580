<template>
  <div class="page1">
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searParkRecordList">
        <el-form
          :inline="true"
          ref="searchWrapper"
          label-position="right"
          :model="formInline"
          class="demo-form-inline"
          :rules="rule"
        >
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item label="错峰卡名称">
                <el-autocomplete
                  class="inline-input"
                  v-model="formInline.staggeredName"
                  :fetch-suggestions="querySearchAsync2"
                  placeholder="请输入错峰卡名称"
                  value-key="staggeredName"
                  :trigger-on-focus="false"
                  @select="handleSelect2"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.park_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue"
                  :fetch-suggestions="querySearchAsync"
                  placeholder="请输入停车场名称"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Statistical_time')">
                <el-date-picker
                  v-model="rangeDate"
                  type="daterange"
                  :picker-options="pickerOptions"
                  :clearable="false"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
                <i class="el-icon-date el-icon-date-custom"></i>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                v-if="$route.meta.authority.button.query"
                >{{ $t('button.search') }}</el-button
              >
              <el-button type="info" icon="el-icon-delete" @click="resetForm()">{{ $t('button.reset') }}</el-button>
            </div>
          </div>
          <div class="col_box_boder"></div>
          <!-- 如果没有下面按钮就不需要 -->
          <div class="col_box h44">
            <div class="col_left">
              <!-- 其他按钮 -->
            </div>
            <div class="col_right mbd4">
              <el-button
                type="info"
                icon="el-icon-upload2"
                @click="exportFile"
                v-if="$route.meta.authority.button.export"
                >{{ $t('button.export') }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="tableData" style="width: 100%">
          <!--<el-table-column type="index" :label="$t('list.index')" width="70"></el-table-column>-->
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { setIndex, dateFormat, exportExcelNew } from "@/common/js/public.js";
import myComponent from "@/components/autocomplete/myautoComponent";
const now = new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000);
const start = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
export default {
  name: "staggeredStatistics",
  data() {
    return {
      payTypeList: [],
      rowData: {},
      temParkData: {},
      temParkData1: {},
      infoSize: 4,
      exportData: {},
      index: 0,
      total: 0,
      pageSize: 15,
      pageNum: 1,
      loading: false,
      modelvalue: "",
      modelvalue1: "",
      rule: {},
      pickerMinDate: "",
      pickerOptions: {
        onPick: (obj) => {
          this.pickerMinDate = new Date(obj.minDate).getTime();
          // console.log(obj);
        },
        disabledDate: (time) => {
          if (this.pickerMinDate) {
            const day1 = 30 * 24 * 3600 * 1000;
            let maxTime = this.pickerMinDate + day1;
            let minTime = this.pickerMinDate - day1;
            return (
              time.getTime() > maxTime ||
              time.getTime() < minTime ||
              time.getTime() > Date.now() - 1 * 24 * 3600 * 1000
            );
          } else {
            return time.getTime() > Date.now() - 1 * 24 * 3600 * 1000;
          }
        },
        // disabledDate: (val) => {
        //   const now = new Date().getTime();
        //   return (
        //     val.getTime() < now - 31 * 24 * 60 * 60 * 1000 ||
        //     val.getTime() > now
        //   );
        // },
      },
      tableCols: [
        {
          prop: "index",
          label: "序号",
          width: "70",
        },
        {
          prop: "staggeredName",
          label: "错峰卡名称",
          width: "",
        },
        {
          prop: "parkName",
          label: this.$t("list.park_name"),
          width: "",
        },
        {
          prop: "orderCount",
          label: "销售数量",
          width: "",
        },

        {
          prop: "money",
          label: this.$t("list.Actual_received_amount"),
          formatter: (row, column) => {
            if (row.money) {
              return Number(row.money / 100).toFixed(2) + "元";
            } else {
              return "0";
            }
          },
        },
      ],

      tableData: [],
      page: 1,
      size: 15,
      rangeDate: [start, now],
      formInline: {
        parkId: "",
        staggeredId: "",
      },
    };
  },
  methods: {
    //   操作
    handleCommand(cmd, data) {
      // console.log(data);
      if (cmd == "view") {
        this.$router.push({
          path: "./staggeredOrderDetail",
          query: {
            staggeredOrderId: data.staggeredOrderId,
          },
        });
      } else if (cmd == "update") {
        this.$router.push({
          path: "./staggeredOrderAE",
          query: {
            staggeredOrderId: data.staggeredOrderId,
          },
        });
      } else if (cmd == "sync") {
        // 重新同步
        this.$axios
          .post("/acb/2.0/staggeredOrder/syncMS", {
            data: {
              staggeredOrderId: data.staggeredOrderId,
            },
          })
          .then((res) => {
            if (res.state == 0) {
              this.searParkRecordList();
            } else {
              this.$alert(res.desc, this.$t('pop_up.Tips'), {
                confirmButtonText: this.$t('pop_up.Determine'),
              });
            }
          });
      }
    },
    querySearchAsync2(queryString, cb) {
      this.loading = false;
      let opt = {
        method: "get",
        url: "/acb/2.0/staggered/dropDown",
        data: {
          staggeredName: this.formInline.staggeredName,
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          res.value.forEach((v) => {
            v.value = v.staggeredName;
          });
          cb(res.value);
        }
      });
    },
    handleSelect2(val) {
      this.formInline.staggeredId = val.staggeredId;
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searParkRecordList();
    },
    /* 停车场 */
    querySearchAsync(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/parkNameList";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: queryString,
            parkTypes: "3,4",
            dataSource: "2",
            slaveRelations: "0,1",
            // slaveRelations: "0,1,2",
            areaIds: this.streetId ? this.streetId : this.areaId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.length ? res.value[0] : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            cb(res.value);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 车牌号 */
    querySearchAsync1(queryString, cb) {
      this.loading = false;
      if (queryString.length < 3) {
        cb([]);
        this.formInline.carId = "";
        return;
      }
      let opt = {
        method: "get",
        url: "/acb/2.0/parkRecord/plateNumber/list",
        data: {
          part: queryString,
          size: 100,
        },
        success: (res) => {
          if (res.state == 0) {
            res.value.forEach((v) => {
              v.value = v.plateNumber;
            });
            cb(res.value);
          }
        },
      };
      this.$axios(opt).then((res) => {
        if (res.state == 0) {
          res.value.forEach((v) => {
            v.value = v.plateNumber;
          });
          cb(res.value);
        }
      });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    handleSelect1(item) {
      this.formInline.plateNumber = item.plateNumber;
      this.formInline.carId = item.carId;
    },
    searchData() {
      if (!this.formInline.parkId) {
        this.modelvalue = this.temParkData.parkName;
        this.formInline.parkId = this.temParkData.parkId;
        this.temParkData = {};
      }
      // console.log(this.formInline);
      let opt = {
        method: "get",
        url: "/acb/2.0/staggeredOrder/dateRangeStatistics",
        data: {
          page: this.pageNum,
          pageSize: this.pageSize,
          parkId: this.formInline.parkId || "",
          startDate: dateFormat(this.rangeDate[0], "yyyy-MM-dd"),
          endDate: dateFormat(this.rangeDate[1], "yyyy-MM-dd"),
          staggeredName: this.formInline.staggeredName,
        },
      };
      this.$axios(opt).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.tableData = setIndex(this.pageNum, res.value.list);
          this.total = res.value.total * 1 || 0;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
      //  getDt(opt)
    },
    // 搜索
    searParkRecordList() {
      this.loading = true;
      if (this.formInline.parkId == "") {
        this.formInline.parkName = "";
      }
      this.searchData();
    },
    exportFile() {
      let data = {
        page: this.pageNum,
        pageSize: this.pageSize,
        parkId: this.formInline.parkId || "",
        startDate: dateFormat(this.rangeDate[0], "yyyy-MM-dd"),
        endDate: dateFormat(this.rangeDate[1], "yyyy-MM-dd"),
      };
      exportExcelNew("/acb/2.0/staggeredOrder/dateRangeStatistics/export", data, "post");
    },
    resetForm() {
      this.modelvalue = "";
      this.rangeDate = [start, now];
      this.pickerMinDate = "";
      this.formInline.staggeredName = "";
      this.formInline.staggeredId = "";
    },
  },
  components: {},
  created() {},
  mounted() {
    // console.log("当前权限为", this.$route.meta.authority.button);
    this.pageNum = 1;
    this.pageSize = 15;
    this.searchData();
  },
  watch: {
    modelvalue(newVal) {
      if (!newVal) {
        this.formInline.parkId = "";
        this.temParkData = {};
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus"></style>
